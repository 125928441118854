/*
 * @Author: zjwei
 * @Email: weizj2000@gmail.com
 * @Date: 2021-04-27 17:34:41
 * @LastEditors: 哈库呐玛塔塔
 * @LastEditTime: 2022-11-16 17:25:42
 * @Description: file content
 */
import React, { useContext, useEffect, useState } from "react"
import MdWrapper from "../../../../../components/MarkdownWrap"

import { useLocation } from "@reach/router"
import Axios from "axios"


const Privacy = () => {
  const [foo, setFoo] = useState(null)
  const location = useLocation()

  useEffect(() => {
    document.documentElement.lang = "es"
    getMd()
  }, [])

  const getMd = () => {
    Axios.get(`${process.env.resourceUrl}docs/nebula_h5_docs/tos/kddi/ToS-ES.md`).then(function (data) {
      setFoo(data.data)
    })

  }
  return (
    <MdWrapper md={foo} blankLayout />
  )
}

export default Privacy
